export const translateMonthId = (monthId) => {
  const monthIdStr = String(monthId);
  const year = parseInt(monthIdStr.slice(0, 4), 10) + 543; // Add 543 to convert to Buddhist calendar
  const month = parseInt(monthIdStr.slice(4, 6), 10) - 1; // JavaScript months are 0-indexed
  const day = parseInt(monthIdStr.slice(6, 8), 10);

  const date = new Date(year - 543, month, day); // Subtract 543 to convert back to Gregorian calendar for Date object

  const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];

  return `วันที่ 1 ${thaiMonths[date.getMonth()]} ถึง ${day} ${
    thaiMonths[date.getMonth()]
  } พ.ศ. ${year}`;
};

// ----------------------------------------------------------------------
export const getPaymentDueDate = (monthId) => {
  const monthIdStr = String(monthId);
  const year = parseInt(monthIdStr.slice(0, 4), 10) + 543; // Add 543 to convert to Buddhist calendar
  const month = parseInt(monthIdStr.slice(4, 6), 10) - 1; // JavaScript months are 0-indexed
  const day = parseInt(monthIdStr.slice(6, 8), 10);

  const date = new Date(year - 543, month, day); // Subtract 543 to convert back to Gregorian calendar for Date object

  const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];

  console.log(thaiMonths[date.getMonth() + 1]);
  return `กรุณาชำระภายในวันที่ 5 ${thaiMonths[date.getMonth() + 1]} พ.ศ. ${year}`;
};

export const translateMonthIdForSummary = (monthId) => {
  const monthIdStr = String(monthId);
  const year = parseInt(monthIdStr.slice(0, 4), 10) + 543;
  const month = parseInt(monthIdStr.slice(4, 6), 10) - 1;

  const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];

  const nextMonth = (month + 1) % 12;
  const nextYear = month === 11 ? year + 1 : year;

  return `บัญชีเก็บค่าเช่าเดือน${thaiMonths[nextMonth]} ${nextYear} ค่าสาธารณูปโภคเดือน ${thaiMonths[month]} ${year}`;
};
