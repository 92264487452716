import useSWR from 'swr';
import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { endpoints, fetcher, poster } from '../utils/axios';

export function useGetInvoices(monthId) {
  const URL = endpoints.invoice.list;
  const { getAccessTokenSilently } = useAuth0();

  const fetchWithToken = async (url) => fetcher(url, getAccessTokenSilently);

  const { data, isLoading, error, isValidating } = useSWR(
    `${URL}?month_id=${monthId}`,
    fetchWithToken
  );
  const memoizedValue = useMemo(
    () => ({
      invoices: data || [],
      invoicesLoading: isLoading,
      invoicesError: error,
      invoicesValidating: isValidating,
      invoicesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetInvoiceMonthIds() {
  const URL = endpoints.invoice.availableMonthIds;

  const { getAccessTokenSilently } = useAuth0();

  const fetchWithToken = async (url) => fetcher(url, getAccessTokenSilently);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetchWithToken);

  const memoizedValue = useMemo(
    () => ({
      invoiceMonthIds: data || [],
      invoiceMonthIdsLoading: isLoading,
      invoiceMonthIdsError: error,
      invoiceMonthIdsValidating: isValidating,
      invoiceMonthIdsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function usePostInvoices() {
  const { getAccessTokenSilently } = useAuth0();

  const postInvoices = async (submitData) => {
    try {
      return await poster(endpoints.invoice.create, submitData, getAccessTokenSilently);
    } catch (error) {
      console.error('Error posting invoices:', error);
      throw error;
    }
  };

  return {
    postInvoices,
  };
}
