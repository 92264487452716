import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { fCurrency } from '../../utils/format-number';
import { translateMonthIdForSummary } from '../../utils/month-translator';
import { useGetInvoices } from '../../api/invoice';
import { useRouter } from '../../routes/hooks';

const printableArea = {
  width: 280,
  height: 190,
};

const actualA4Size = {
  width: 297,
  height: 210,
};

const PrintOnlyDiv = styled('div')({
  height: `${actualA4Size.height}mm`,
  width: `${actualA4Size.width}mm`,
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const ContentTableCell = styled(TableCell)({
  border: '1px solid black',
  textAlign: 'center',
  height: '50px',
  padding: '5px',
});

const HeaderTableCell = styled(TableCell)({
  textAlign: 'center',
});

const PrintableInvoicesSummary = () => {
  const location = useLocation();
  const { replace } = useRouter();
  const { isAuthenticated } = useAuth0();

  const monthId = parseInt(new URLSearchParams(location.search).get('monthId'), 10);
  const { invoices } = useGetInvoices(monthId);

  if (!isAuthenticated) {
    replace('404');
  }

  useEffect(() => {
    if (invoices.length > 0) {
      const input = document.getElementById('printableInvoicesSummary');
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF('landscape', 'mm', 'a4');
          const pdfWidth = printableArea.width;
          const pdfHeight = printableArea.height;
          pdf.addImage(
            imgData,
            'PNG',
            Math.round((actualA4Size.width - printableArea.width) / 2),
            0,
            pdfWidth,
            pdfHeight
          );
          pdf.autoPrint();
          console.log("pdf.output('bloburl')", pdf.output('bloburl'));
          window.open(pdf.output('bloburl'), '_blank');
        })
        .catch((err) => console.error('Error generating PDF', err))
        .finally(() => console.log('PDF generated'));
    }
  }, [monthId, invoices]);

  const totalRoom = invoices.reduce(
    (total, invoice) =>
      total +
      invoice.items
        .filter((item) => item.name === 'ค่าห้อง')
        .reduce((t, item) => t + parseFloat(item.total), 0),
    0
  );

  const totalWater = invoices.reduce(
    (total, invoice) =>
      total +
      invoice.items
        .filter((item) => item.name === 'ค่าน้ำ')
        .reduce((t, item) => t + parseFloat(item.total), 0),
    0
  );

  const totalElectricity = invoices.reduce(
    (total, invoice) =>
      total +
      invoice.items
        .filter((item) => item.name === 'ค่าไฟฟ้า')
        .reduce((t, item) => t + parseFloat(item.total), 0),
    0
  );

  const superTotal = invoices.reduce(
    (total, invoice) => total + invoice.items.reduce((t, item) => t + parseFloat(item.total), 0),
    0
  );

  return (
    <PrintOnlyDiv id="printableInvoicesSummary">
      <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '10px' }}>
        {translateMonthIdForSummary(monthId)}
      </Typography>
      <Table sx={{ marginTop: '10px' }}>
        <TableHead>
          <TableRow>
            <HeaderTableCell>
              <Typography variant="h6">ลำดับ</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">ห้อง</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">ชื่อผู้เช่า</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">ค่าห้อง</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">ค่าน้ำ</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">ค่าไฟฟ้า</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">รวม</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">วันที่ชำระ</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">ผู้รับชำระ</Typography>
            </HeaderTableCell>
            <HeaderTableCell>
              <Typography variant="h6">หมายเหตุ</Typography>
            </HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice, index) => {
            const roomPrice = invoice.items
              .filter((item) => item.name === 'ค่าห้อง')
              .reduce((total, item) => total + parseFloat(item.total), 0);

            const waterPrice = invoice.items
              .filter((item) => item.name === 'ค่าน้ำ')
              .reduce((total, item) => total + parseFloat(item.total), 0);

            const electricityPrice = invoice.items
              .filter((item) => item.name === 'ค่าไฟฟ้า')
              .reduce((total, item) => total + parseFloat(item.total), 0);

            const netTotal = invoice.items.reduce(
              (total, item) => total + parseFloat(item.total),
              0
            );

            return (
              <TableRow key={invoice.id}>
                <ContentTableCell>{index + 1}</ContentTableCell>
                <ContentTableCell>{invoice.leaseAgreement.room.code}</ContentTableCell>
                <TableCell
                  sx={{ border: '1px solid black', padding: '5px' }}
                >{`${invoice.leaseAgreement.rental.first_name} ${invoice.leaseAgreement.rental.last_name}`}</TableCell>
                <ContentTableCell>{fCurrency(roomPrice)}</ContentTableCell>
                <ContentTableCell>{waterPrice > 0 ? fCurrency(waterPrice) : '0'}</ContentTableCell>
                <ContentTableCell>
                  {electricityPrice > 0 ? fCurrency(electricityPrice) : '0'}
                </ContentTableCell>
                <ContentTableCell>{fCurrency(netTotal)}</ContentTableCell>
                <ContentTableCell>{invoice.paymentDate}</ContentTableCell>
                <ContentTableCell>{invoice.receivedBy}</ContentTableCell>
                <ContentTableCell>{invoice.memo}</ContentTableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell sx={{ textAlign: 'center', border: '1px solid black' }}>
              <Typography variant="h6">รวม</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center', border: '1px solid black' }}>
              <Typography variant="h6">{fCurrency(totalRoom)}</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center', border: '1px solid black' }}>
              <Typography variant="h6">{fCurrency(totalWater)}</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center', border: '1px solid black' }}>
              <Typography variant="h6">{fCurrency(totalElectricity)}</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center', border: '1px solid black' }}>
              <Typography variant="h6">{fCurrency(superTotal)}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </PrintOnlyDiv>
  );
};

PrintableInvoicesSummary.propTypes = {};

export default PrintableInvoicesSummary;
