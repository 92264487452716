import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (url, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently();
  try {
    const response = await axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// ----------------------------------------------------------------------

export const poster = async (url, payload, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently();
  const res = await axiosInstance.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  meterReading: {
    list: '/meter-readings',
    availableMeterReadingDates: '/meter-readings/available-reading-dates',
    create: '/meter-readings',
  },
  invoice: {
    list: '/invoices',
    availableMonthIds: '/invoices/available-month-ids',
    create: '/invoices',
  },
  leaseAgreement: {
    list: '/lease-agreements',
  },
  meters: {
    list: '/meters',
  },
};
